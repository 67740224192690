import React from 'react';
import Collapsible from 'react-collapsible';
import { FormattedMessage } from '../../util/reactIntl';
import { availabilityOptions as availabilityConfig } from '../EditListingPage/EditListingWizard/EditListingProgramsAndAvailabilityPanel/EditListingProgramsAndAvailabilityForm';
import {
  IconArrowHead,
  NamedLink,
  ExternalLink,
  PrimaryButton,
} from '../../components';

import css from './ListingPage.module.css';

const toTrigger = program => {
  const { name, age, availability, } = program;
  const isOpenSpots = availability === 'openSpots'
  const isWaitList = availability === 'waitList'
  const openSpot = <span className={css.openSpot}><FormattedMessage id="ListingCard.openSpot" /></span>
  const waitlist = <span className={css.waitlist}><FormattedMessage id="ListingCard.waitlist" /></span>
  const noAvailability = <span className={css.noAvailability}><FormattedMessage id="ListingCard.noAvailability" /></span>
  const availabilityTag = isOpenSpots ? openSpot : isWaitList ? waitlist : noAvailability
  const ageTag = <span className={css.ageTag}><FormattedMessage id="ListingPage.programAge" values={{ ageFrom: age.from, ageTo: age.to }} /></span>
  return (
    <div className={css.trigger}>
      <div className={css.programNameWrapper}>
        <div className={css.label}>
          <FormattedMessage id="ListingPage.programNameTitle" />
        </div>
        <div className={css.value}>
          {name}
        </div>
      </div>
      <div className={css.tags}>
        <div className={css.tagsWrapper}>
          <div className={css.age}>{ageTag}</div>
          <div className={css.availability}>{availabilityTag}</div>
        </div>
        <div className={css.iconArrowDown}>
          <IconArrowHead direction="down" />
        </div>
      </div>
    </div>
  );
}
const SectionProgramsMaybe = props => {
  const { intl, publicData, isAuthenticated, onSendMessage } = props;
  const { programs } = publicData || {};
  const hasPrograms = programs && programs.length > 0;
  return hasPrograms ? (
    <section className={css.sectionPrograms}>
      <div className={css.label}>
        <FormattedMessage id="ListingPage.programs" />
      </div>
      {programs.map(program => {
        const { description, availability, availabilityOptions, waitlist, customLink, depositAmount, monthlyFee, offering, applyOption } = program;
        const isOpenSpots = availability === 'openSpots'
        const isWaitList = availability === 'waitList'
        const isNotAvailable = availability === 'noAvailability'
        const careTimes = availabilityConfig(intl).filter(avail => availabilityOptions?.includes(avail.key)).map(avail => avail.label).join(', ')
        const subsidiesAndgrants = offering.map(off => `${off} ${intl.formatMessage({ id: 'ListingPage.eligible' })}`).join(', ')

        const cta = <div className={css.links}>
          {
            isOpenSpots && customLink ?
              <ExternalLink className={css.bookingLink} href={customLink} target="_blank">
                <FormattedMessage id="InquiryWithoutPaymentForm.bookingLink" />
              </ExternalLink> :
              isOpenSpots && isAuthenticated ?
                <PrimaryButton type="submit" className={css.sendMessage} onClick={onSendMessage}>
                  <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                </PrimaryButton> :
                isOpenSpots ?
                  <NamedLink name="SignupPage" className={css.sendMessage}>
                    <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                  </NamedLink> :
                  isWaitList && customLink ?
                    <ExternalLink className={css.waitListLink} href={customLink} target="_blank">
                      <FormattedMessage id="InquiryWithoutPaymentForm.waitListLink" />
                    </ExternalLink> :
                    isWaitList && isAuthenticated ?
                      <PrimaryButton type="submit" className={css.sendMessage} onClick={onSendMessage}>
                        <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                      </PrimaryButton> :
                      isWaitList ?
                        <NamedLink name="SignupPage" className={css.sendMessage}>
                          <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                        </NamedLink> :
                        isNotAvailable && isAuthenticated ?
                          <PrimaryButton type="submit" className={css.sendMessage} onClick={onSendMessage}>
                            <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                          </PrimaryButton> :
                          isNotAvailable ?
                            <NamedLink name="SignupPage" className={css.sendMessage}>
                              <FormattedMessage id="InquiryWithoutPaymentForm.ctaButton" />
                            </NamedLink> : null
          }
        </div>
        return (
          <div className={css.programContainer}>
            <Collapsible
              trigger={toTrigger(program)}
              triggerOpenedClassName={css.collapsible}
              openedClassName={css.collapsibleOpen}
            >
              <div className={css.program}>
                <div className={css.row}>
                  {
                    !!waitlist ?
                      <div className={css.waitlistTimeLines}>
                        <div className={css.label}>
                          <FormattedMessage id="ListingPage.waitlistTimeLinesLabel" />
                        </div>
                        <div className={css.value}>
                          <FormattedMessage id="ListingPage.waitlistTimeLines" values={{ month: waitlist.month, year: waitlist.year }} />
                        </div>
                      </div> : null
                  }
                  {
                    !!careTimes ?
                      <div className={css.careTimes}>
                        <div className={css.label}>
                          <FormattedMessage id="ListingPage.careTimesLabel" />
                        </div>
                        <div className={css.value}>
                          <FormattedMessage id="ListingPage.careTimes" values={{ careTimes }} />
                        </div>
                      </div> : null
                  }
                </div>
                <div className={css.row}>
                  {!!monthlyFee ?
                    <div className={css.monthlyFees}>
                      <div className={css.label}>
                        <FormattedMessage id="ListingPage.monthlyFeesLabel" />
                      </div>
                      <div className={css.value}>
                        <FormattedMessage id="ListingPage.monthlyFees" values={{ currency: '$', monthlyFee }} />
                      </div>
                    </div> : null
                  }
                  {
                    !!depositAmount ?
                      <div className={css.deposit}>
                        <div className={css.label}>
                          <FormattedMessage id="ListingPage.depositLabel" />
                        </div>
                        <div className={css.value}>
                          <FormattedMessage id="ListingPage.deposit" values={{ currency: '$', depositAmount }} />
                        </div>
                      </div> : null
                  }
                  {
                    !!subsidiesAndgrants ?
                      <div className={css.subsidiesAndgrants}>
                        <div className={css.label}>
                          <FormattedMessage id="ListingPage.subsidiesAndgrantsLabel" />
                        </div>
                        <div className={css.value}>
                          <FormattedMessage id="ListingPage.subsidiesAndgrants" values={{ subsidiesAndgrants }} />
                        </div>
                      </div> : null
                  }
                </div>
                <div>
                  <div className={css.label}>
                    <FormattedMessage id="ListingPage.description" />
                  </div>
                  <div className={css.value}>
                    {description}
                  </div>
                  {cta}
                </div>
              </div>
            </Collapsible>
          </div>
        );
      })}
    </section>
  ) : null;
};

export default SectionProgramsMaybe;